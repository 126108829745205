import type { Theme } from '@emotion/react';
import { buildTheme } from 'common-ui';
import merge from 'lodash/merge';
import type { CommonThemeVars, ThemeCreateFunction } from 'tsconfig/types';
import { buildThemeMixinsUsLW, buildThemeWidgetStylesUsLW, buildThemeVarsUsLW } from 'dw-us-law/theme';
const createTheme: ThemeCreateFunction = () =>
  buildTheme(
    (vars: Pick<Theme, CommonThemeVars>) => merge({}, buildThemeVarsUsLW(vars)),
    (vars: Omit<Theme, 'mixins' | 'widgets'>) => buildThemeMixinsUsLW(vars),
    (vars: Omit<Theme, 'widgets'>) => {
      const buildThemeWidget = buildThemeWidgetStylesUsLW(vars);
      return merge({}, buildThemeWidget, {
        Footer: {
          default: `
          ${buildThemeWidget.Footer.default}
          .footer-nav-links .tab-panel ul{
            text-align: left;
            li{
              font-family: ${vars.fonts.familySecondary};
            }
          }
          `,
          navItems: `
            ${buildThemeWidget.Footer.navItems}
            > div:first-of-type p{
              font-family: ${vars.fonts.familySecondary};
              text-transform: uppercase;
              font-weight: bold;
            }
          `,
        },
      });
    },
  );

export default createTheme;