import React, { useEffect, useState } from 'react';
import { useDisclosure } from '@mantine/hooks';
import { Modal } from '@mantine/core';
import { css, useTheme } from '@emotion/react';
import { Icon } from '../../Icon/Icon';
import {
  getInventoryLimitCheck,
  getMaxLimitCheck,
  globals,
  handleDLMiniCartClick,
  ITEMCLASSIFICATION_TYPE_GIFT,
} from 'common-ui';
import { useRecoilValue } from 'recoil';
import { selectCartData } from 'common-state';
import type { LineItem, MiniCartModalProps } from 'tsconfig/types';
import MiniCartLayout from '../MiniCartLayout';

const Title = () => (
  <div className="mini-cart-header">
    <Icon kind="tick-circle" size="l" />
    <h3> {globals?.country !== 'uk' ? 'In your cart' : 'Added to basket'}</h3>
  </div>
);

const MiniCartModal: React.FC<MiniCartModalProps> = ({
  show,
  size = 'xl',
  onClose,
  selectedItemCode,
  qty,
}: MiniCartModalProps) => {
  const theme = useTheme();
  const [opened, { close }] = useDisclosure(false);
  const [animationClass, setAnimationClass] = useState('slide-in');
  const { cartItems } = useRecoilValue(selectCartData);
  const [selectedItem, setSelectedItem] = useState<LineItem[] | undefined>();
  const [isFreeGiftLineItem, setIsFreeGiftLineItem] = useState(false);
  const [isGreatPick, setIsGreatPick] = useState(false);
  const [showCheckoutButton, setShowCheckoutButton] = useState(false);
  const [currentMiniCartItemCode, setCurrentMiniCartItemCode] = useState<string>('');

  const MiniCartWrapper = css`
    ${theme.widgets?.Cart?.MiniCart || ''}
    .mantine-Modal-content {
      animation: ${animationClass === 'slide-in' ? 'slideInFromRight' : 'slideOutToRight'} 0.2s ease-in-out !important;
    }
  `;

  useEffect(() => {
    const item = cartItems?.lineItems?.filter(product => product?.sku?.itemCode === selectedItemCode);
    if (item?.length) {
      setSelectedItem(item.length > 1 ? [item[item.length - 1]] : item);
    }

    if (currentMiniCartItemCode !== '' && selectedItemCode !== currentMiniCartItemCode) {
      setCurrentMiniCartItemCode(selectedItemCode);
      setIsGreatPick(true);
    } else {
      setCurrentMiniCartItemCode(selectedItemCode);
    }

    const inventoryLimitCheck = cartItems?.lineItems ? getInventoryLimitCheck(cartItems.lineItems) : false;
    const maxLimitCheck = cartItems?.lineItems ? getMaxLimitCheck(cartItems?.lineItems) : false;

    const showCheckoutButtonVal = inventoryLimitCheck || maxLimitCheck;
    setShowCheckoutButton(!showCheckoutButtonVal);
    const freeItem = cartItems?.lineItems?.filter(cartItem =>
      cartItem?.itemClassifications?.some(classification =>
        classification.toLowerCase().includes(ITEMCLASSIFICATION_TYPE_GIFT),
      ),
    );
    const bulkItems =
      cartItems?.lineItems?.filter(eachItem =>
        eachItem?.sku?.productName?.[0]?.value?.toLowerCase().includes('bulk'),
      ) ?? [];
    if (freeItem && freeItem.length > 0 && bulkItems?.length === 0) {
      setIsFreeGiftLineItem(true);
    }
  }, [cartItems, selectedItemCode]);

  const handleClose = () => {
    setAnimationClass('slide-out');
    const timer = setTimeout(() => {
      close();
      onClose?.();
    }, 200);
    return () => clearTimeout(timer);
  };

  const handleMiniCartClickEvents = (eventType: string) => {
    eventType && handleDLMiniCartClick('miniCartClick', eventType);
  };

  return selectedItem?.length ? (
    <Modal
      css={MiniCartWrapper}
      opened={opened || !!show}
      className={`mantine-Modal-content-minicart vine-react-app ${animationClass}`}
      onClose={() => {
        handleClose();
        handleMiniCartClickEvents('closeMiniCartClick');
      }}
      title={<Title />}
      size={size}
    >
      <div style={{ position: 'relative' }}>
        <MiniCartLayout
          lineItems={selectedItem}
          selectedItemCode={selectedItemCode}
          totalLineItem={cartItems?.totalLineItems ?? 0}
          onClose={handleClose}
          isFreeGiftLineItem={isFreeGiftLineItem}
          isGreatPick={isGreatPick}
          qty={qty}
          showCheckoutButton={showCheckoutButton}
        />
      </div>
      <div
        className="continue-shopping desktop"
        onClick={() => {
          handleClose();
          handleMiniCartClickEvents('continueShoppingtMiniCartClick');
        }}
        onKeyDown={() => {
          handleClose();
          handleMiniCartClickEvents('continueShoppingtMiniCartClick');
        }}
        role="button"
        tabIndex={0}
      >
        <span className="continue-shopping-text">continue shopping</span>
      </div>
      <div className="tax">
        <p className="tax-info">
          {globals.country === 'au' &&
            selectedItem?.[0]?.itemClassifications?.includes('PRESELL_ITEM') &&
            'The price shown is the total for this wine. You will only be charged the reservation payment today.'}
        </p>
        <p className="tax-info">
          {globals.country === 'au'
            ? 'All prices are inclusive of GST. Any additional discounts will be displayed at checkout.'
            : globals.country === 'uk'
              ? 'Any additional discounts will display at checkout.'
              : 'Applicable tax, shipping and any additional discounts will display at checkout'}
        </p>
      </div>
    </Modal>
  ) : null;
};

export default MiniCartModal;
