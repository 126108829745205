import type { ProductProps } from 'tsconfig/types';
import { css, useTheme } from '@emotion/react';
import { Tooltip } from '@mantine/core';
import { globals } from 'common-ui';

const medalImageMapping: { [key: string]: string } = {
  Gold: '/next/images/medals/gold_medal.svg',
  Silver: '/next/images/medals/silver_medal.svg',
  Bronze: '/next/images/medals/bronze_medal.svg',
  'Double Gold': '/next/images/medals/2_gold.svg',
  'Triple Gold': '/next/images/medals/3_gold.svg',
  'Four Gold': '/next/images/medals/4_gold.svg',
};

export const AwardDetails: React.FC<{ product: ProductProps }> = ({ product }) => {
  const { accolades } = product;
  let mappedAccolades = accolades;
  if (accolades.length < 1) {
    const referencedSkus = product?.skus[0]?.referencedSkus ? product.skus[0].referencedSkus : [];
    mappedAccolades = referencedSkus
      .map(item => item.expandedItem.accolades)
      .reduce((acc, accolade) => acc.concat(accolade), []);
  }

  const theme = useTheme();
  const wrapper = css`
    ${theme.widgets.Product?.AwardDetails}
  `;

  const getImgUrl = ({
    accoladeLevelDescription,
    accoladeTypeDescription,
  }: {
    accoladeLevelDescription: string;
    accoladeTypeDescription: string;
  }): string => {
    if (accoladeTypeDescription === 'Medal') {
      return medalImageMapping[accoladeLevelDescription];
    }
    if (accoladeTypeDescription === 'Points') {
      return '/next/images/medals/points.svg';
    }
    return '/next/images/medals/trophy.svg';
  };

  const isExcludedAccolade = (accoladeTypeDescription: string, isAUMarket: boolean) => {
    const excludedAccolades = isAUMarket ? ['QUOTE', 'Packaging Pioneers'] : ['QUOTE'];
    return excludedAccolades.includes(accoladeTypeDescription);
  };

  const isAUMarket = globals.country?.toLowerCase() === 'au'; // Check if the country is Australia

  return (
    <div css={wrapper} className="award-main-container">
      {mappedAccolades
        .filter(({ accoladeTypeDescription = '' }) => !isExcludedAccolade(accoladeTypeDescription, isAUMarket))
        .map(
          ({
            accoladeId,
            accoladeLevelDescription = '',
            accoladeTypeDescription = '',
            accoladeOrgDescription = '',
          }) => {
            const imageUrl = getImgUrl({ accoladeLevelDescription, accoladeTypeDescription });

            return (
              <Tooltip
                withArrow
                arrowSize={20}
                arrowPosition="side"
                position="top"
                label={
                  <div className="award-item">
                    {`${accoladeOrgDescription}, (${accoladeLevelDescription} ${accoladeTypeDescription})`}
                  </div>
                }
                key={`accolade-${accoladeId}`}
                transitionProps={{ transition: 'fade', duration: 300 }}
                events={{ hover: true, focus: true, touch: true }}
              >
                <div className="medal award-text">
                  {accoladeTypeDescription === 'Points' ? (
                    <div className="point-main-container">
                      <img src={imageUrl} alt={accoladeLevelDescription} />
                      <div className="point-text-container">
                        <span className="point">{accoladeLevelDescription}</span>
                        <span className="point-text">PTS</span>
                      </div>
                    </div>
                  ) : (
                    <img src={imageUrl} alt={accoladeLevelDescription} />
                  )}
                </div>
              </Tooltip>
            );
          },
        )}
    </div>
  );
};
