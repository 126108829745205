export const enum ProductType {
  Bottles = 'Bottles',
  Boxes = 'Boxes',
  Cans = 'Cans',
  Case = 'Case',
  Set = 'Set Of',
}

export const enum OfferPrice {
  VppPrice = 'when you mix',
  MemberPrice = 'Unlimited Member Price',
  MemberPriceSavings = 'Unlimited Member Savings',
  AdvantageMemberPrice = 'Advantage Member Price',
  AdvantageMemberPriceSavings = 'Advantage Member Savings',
}

export const enum CartButtonText {
  AddToCart = 'ADD TO CART',
  ViewOffers = 'VIEW OFFERS',
  ReserveToday = 'RESERVE TODAY',
}

export const enum PerProduct {
  Bottle = 'per bottle',
  Box = 'per box',
  Can = 'per can',
  Case = 'per case',
  Item = 'per item',
}
export const enum Packaging {
  Bottle = 'bottle',
  Can = 'can',
  Box = 'box',
}

export const enum SliderTitle {
  ChooseYourQuantity = 'Choose your quantity',
}

export const enum CommonText {
  Save = 'Save',
}

export const enum PricingLabel {
  PerCase = 'per case',
  Mix12Plus = 'when you mix 12+',
  FirstInstallment = 'first installment',
  RRP = 'RRP',
}

export const enum AvailabilityStatus {
  InStock = 'in_stock',
  LowStock = 'low_stock',
  OutOfStock = 'out_of_stock',
}

// Define enums for brand IDs and card types
export const enum BrandId {
  LAW = 'law',
  LW = 'lw',
}

export const enum CardType {
  DEPOSIT = 'DEPOSIT',
  GIFT_CARD = 'GIFT_CARD',
}
