import React from 'react';
import type { ReactNode } from 'react';

// * Next/Image is designed to work with the Next build environment. For various reason we want Next Images to
// * work outside of Next (e.g. embedded apps). This workaround fixes the build issues

interface LinkProps {
  href: string;
  children: ReactNode;
  legacyBehavior?: boolean;
  [key: string]: any; // Allows for additional props of any type
}

export const Link: React.FC<LinkProps> = ({ href, children, legacyBehavior, passHref, ...otherProps }) => {
  if (legacyBehavior || passHref) {
    return React.cloneElement(children as React.ReactElement, { href });
  }
  return (
    <a href={href} {...otherProps}>
      {children}
    </a>
  );
};
//an additional aliasfor Link thats useful for clarity in common-ui code (see the common-ui Link)
export const NextLink = Link;

interface ImageProps extends React.ImgHTMLAttributes<HTMLImageElement> {
  [key: string]: any; // Allows for additional props of any type
}

export const Image: React.FC<ImageProps> = ({ alt = '', ...otherProps }) => <img alt={alt} {...otherProps} />;
