import { addDays, format } from 'date-fns';

/**
 * Calculates the estimated delivery date based on the current date and standard delivery days.
 * @param {number} standardDeliveryDays - The number of days for standard delivery (default is 3).
 * @returns {string} - The formatted estimated delivery date.
 */
export const getDayWithSuffix = (date: Date): string => {
  const day = format(date, 'd');
  const suffix =
    day.endsWith('1') && !day.endsWith('11')
      ? 'st'
      : day.endsWith('2') && !day.endsWith('12')
        ? 'nd'
        : day.endsWith('3') && !day.endsWith('13')
          ? 'rd'
          : 'th';
  return `${day}${suffix}`;
};
export function calculateEstimatedDeliveryDate(standardDeliveryDays = 3): string {
  const startDate = addDays(new Date(), standardDeliveryDays);
  const endDate = addDays(startDate, 1);

  const startDay = getDayWithSuffix(startDate);
  const endDay = getDayWithSuffix(endDate);

  const startMonth = format(startDate, 'MMM');
  const endMonth = format(endDate, 'MMM');

  return startMonth === endMonth
    ? `${startDay} - ${endDay} ${endMonth}`
    : `${startDay} ${startMonth} - ${endDay} ${endMonth}`;
}

/**
 * Get the count down timer message from the given date string.
 * @param {string} dateString - The date string of datetime when the count down ends.
 * @returns {string} - The formatted count downtimer message. (e.g. "1 day : 3 hours : 5 minutes" )
 */
export function getCountDownTime(dateString: string): string {
  let text = '';
  const time = Date.parse(dateString) - Date.now();
  const d = Math.floor(time / (1000 * 60 * 60 * 24));
  const h = Math.floor((time / (1000 * 60 * 60)) % 24);
  const m = Math.floor((time / 1000 / 60) % 60);
  if (d > 0) {
    text += `${d} day${d !== 1 ? 's' : ''} : `;
  }
  if (d > 0 || h > 0) {
    text += `${h} hour${h !== 1 ? 's' : ''} : `;
  }
  if (d > 0 || h > 0 || m > 0) {
    text += `${m} minute${m !== 1 ? 's' : ''}`;
  }
  return text;
}
/**
 * Verifies that start and end date are within today.
 * @param {string} startDate - The start date string of datetime.
 * @param {string} endDate - The end date string of datetime.
 * @returns {boolean} - Verifies that today is between start and end date.
 */
export const isInDateRange = (startDate: string, endDate: string) => {
  const today = new Date();
  const start = new Date(startDate);
  const end = new Date(endDate);

  return today >= start && today <= end;
};
